import Login from "@/components/features/Login/Login";
import { AppSuspense } from "@/components/layout/AppSuspense";
import { useFetchAll } from "@/lib/hooks/use-fetch";
import { authQuery } from "@/lib/queries/auth";
import { organizationQuery } from "@/lib/queries/organization";
/**
 * @param {import('@tanstack/react-query').QueryClient} queryClient
 * @returns {function(): Promise<{organization: any, properties: any}>}
 */
export const loader = (queryClient) =>
  async function loader() {
    const orgPromise = queryClient.ensureQueryData(organizationQuery());
    const authPromise = queryClient.ensureQueryData({
      ...authQuery(),
      throwOnError: false,
    });

    await Promise.allSettled([orgPromise, authPromise]);

    return {
      organization: orgPromise,
      auth: authPromise ? authPromise : null,
    };
  };

export const LoginRoute = () => {
  return (
    <AppSuspense>
      <LoginData>
        <Login />
      </LoginData>
    </AppSuspense>
  );
};

const LoginData = ({ children }) => {
  useFetchAll({ queries: [organizationQuery(), authQuery()] });

  return <>{children}</>;
};
