import { setKohostOrganizationId } from "../api/client";
import { organizationQuery } from "../queries/organization";
import { getQueryClient } from "../queries/queryClient";
import { useFetch } from "./use-fetch";
export function useOrganization() {
  const appQueryClient = getQueryClient("app");
  const { data: organization } = useFetch(organizationQuery(), appQueryClient);

  if (organization?.id) {
    setKohostOrganizationId(organization.id);
  }

  return { organization };
}
