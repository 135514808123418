import { cn } from "@/lib/utils";
import React, { useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";

import LoadingSpinner from "components/common/LoadingSpinner";
import LoginForm from "./LoginForm";
import Logo from "./Logo";

import { useAuth } from "@/lib/hooks/use-auth";
import { parseJwt } from "lib/string/parsers";

import myKeyIcon from "@/assets/images/key-app-icon.png";
import { useOrganization } from "@/lib/hooks/use-organization";
import CurrentUserConfirmation from "./CurrentUserConfirmation";

const redirectQueryKey = "redirect_uri";
const intentQueryKey = "intent";
const tokenQueryKey = "token";
const orgIdQuery = "organizationId";

export const Login = () => {
  const {
    startLogin,
    finishLogin,
    getToken,
    registerPasskey,
    getSavedPasskeys,
    currentUser,
  } = useAuth();

  const [searchParams, setSearchParams] = useSearchParams();

  const loginToken = searchParams.get(tokenQueryKey);
  const intent = searchParams.get(intentQueryKey);
  const redirectUri = searchParams.get(redirectQueryKey);

  const redirectToSameOrigin = redirectUri?.includes(window.location.origin);

  const [loading, setLoading] = React.useState(loginToken !== null);
  const [logoLoaded, setLogoLoaded] = React.useState(false);

  const [showCurrentUserConfirmation, setShowCurrentUserConfirmation] =
    React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");

  useEffect(() => {
    if (currentUser?.id && !loginToken) {
      setShowCurrentUserConfirmation(true);
    }
  }, [currentUser?.id, loginToken]);

  const { organization } = useOrganization();
  const organizationId = organization?.id;

  const loginForm = useRef(null);
  const logo = useRef(null);
  const container = useRef(null);
  const loader = useRef(null);

  const appManifest = organization?.appManifest;

  let logoSrc = intent === "digitalKey" ? myKeyIcon : appManifest?.logo?.src;
  if (!logoSrc) logoSrc = myKeyIcon;

  const {
    mutateAsync: handleFinishLogin,
    isPending: isFinishingLogin,
    isSuccess: finishLoginSuccess,
    isError: finishLoginError,
    reset: resetFinishLogin,
  } = finishLogin;
  const { mutateAsync: handleGetToken } = getToken;
  const { mutateAsync: handleRegisterPasskey } = registerPasskey;

  useEffect(() => {
    const autoLoginWithToken = async () => {
      try {
        const loginPayload = parseJwt(loginToken);
        await handleFinishLogin(
          {
            data: { ...loginPayload, token: loginToken },
          },
          {
            onError: () => {
              setErrorMessage("Session expired, please login again.");
              setLoading(false);
              setSearchParams((params) => {
                params.delete(tokenQueryKey);
                return params;
              });
            },
            onSuccess: async () => {
              try {
                const hasPasskeys = await getSavedPasskeys();
                if (hasPasskeys?.length === 0) {
                  await handleRegisterPasskey();
                }
              } catch (error) {
                console.error(error);
              }

              window.open(redirectUri || "/", "_self");
            },
          },
        );
      } catch (error) {
        setLoading(false);
      }
    };

    if (
      loginToken &&
      !isFinishingLogin &&
      !finishLoginSuccess &&
      !finishLoginError
    ) {
      autoLoginWithToken();
    }
  }, [
    loginToken,
    handleFinishLogin,
    isFinishingLogin,
    finishLoginSuccess,
    handleRegisterPasskey,
    getSavedPasskeys,
    redirectUri,
    finishLoginError,
    setSearchParams,
  ]);

  const handleSuccess = async () => {
    try {
      setLoading(true);
      resetFinishLogin();

      const { token } = await handleGetToken();

      setSearchParams((params) => {
        if (!redirectUri) {
          params.set(redirectQueryKey, window.location.origin);
          params.set(tokenQueryKey, token);
        } else if (!redirectToSameOrigin) {
          let redirectUriWithToken = `${redirectUri}?${tokenQueryKey}=${token}`;

          if (organizationId)
            redirectUriWithToken += `&${orgIdQuery}=${organizationId}`;
          else {
            // get the organization from the token payload
            const orgId = parseJwt(token)?.organization;
            if (orgId) redirectUriWithToken += `&${orgIdQuery}=${orgId}`;
          }
          params.set(redirectQueryKey, redirectUriWithToken);
        }

        return params;
      });
    } catch (error) {
      setErrorMessage("");
      setLoading(false);
    }
  };

  const queryOrg = searchParams.get(orgIdQuery);

  useEffect(() => {
    if (!redirectToSameOrigin && redirectUri?.includes(tokenQueryKey)) {
      try {
        if (!redirectUri.includes(orgIdQuery) && queryOrg) {
          const withOrgId = `${redirectUri}&${orgIdQuery}=${queryOrg}`;
          window.open(withOrgId, "_self");
        } else {
          window.open(redirectUri, "_self");
        }
      } catch (error) {
        console.log(error);
      }
    }
  }, [redirectToSameOrigin, redirectUri, queryOrg]);

  if (loading) return <LoadingSpinner fullScreen height={36} width={36} />;

  return (
    <>
      <div
        className={cn(
          "relative h-screen overflow-x-hidden transition-opacity duration-100 h-screen-ios",
          {
            "opacity-0": !logoLoaded,
          },
        )}
      >
        <div
          ref={container}
          className="relative mx-8 flex h-screen max-w-2xl flex-col justify-center transition-all h-screen-ios md:mx-auto"
        >
          <div className="mb-4 text-left">
            <Logo ref={logo} onLoad={() => setLogoLoaded(true)} src={logoSrc} />
          </div>

          <LoginForm
            ref={loginForm}
            onLoginSuccess={handleSuccess}
            startLogin={startLogin.mutateAsync}
            finishLogin={finishLogin.mutateAsync}
            errorMessage={errorMessage}
            onError={setErrorMessage}
            successMessage={successMessage}
            onSuccess={setSuccessMessage}
          />
        </div>
      </div>
      <CurrentUserConfirmation
        show={showCurrentUserConfirmation}
        user={currentUser}
        onClose={() => setShowCurrentUserConfirmation(false)}
        onConfirm={handleSuccess}
      />
      <div ref={loader} className="fixed inset-0 hidden bg-dark-gray opacity-0">
        <LoadingSpinner fullScreen height={36} width={36} />
      </div>
    </>
  );
};

export default Login;
