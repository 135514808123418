import { AppSuspense } from "@/components/layout/AppSuspense";
import { setKohostPropertyId } from "@/lib/api/client";
import { getStoredPropertyId, storePropertyId } from "@/lib/data/property";
import { useTheme } from "@/lib/hooks/use-theme";
import { authQuery } from "@/lib/queries/auth";
import { organizationQuery } from "@/lib/queries/organization";
import { propertiesQuery } from "@/lib/queries/properties";
import {
  createQueryClient,
  registerQueryClient,
} from "@/lib/queries/queryClient";
import React from "react";
import { redirect, useLoaderData } from "react-router-dom";

/**
 * @param {import('@tanstack/react-query').QueryClient} queryClient
 * @returns {function(): Promise<{organization: any, properties: any}>}
 */
export const loader = (queryClient) =>
  async function loader({ request }) {
    const requestUrl = new URL(request.url);
    const orgPromise = queryClient.ensureQueryData(organizationQuery());
    const propsPromise = queryClient.ensureQueryData(propertiesQuery());
    const authPromise = queryClient.ensureQueryData(authQuery());

    const results = await Promise.allSettled([
      orgPromise,
      propsPromise,
      authPromise,
    ]);

    const [organization, properties, auth] = results.map((result) => {
      if (result.status === "fulfilled") {
        return result.value;
      }
      return null;
    });

    if (auth === null && requestUrl.pathname !== "/login") {
      return redirect("/login");
    }

    // register query clients for each property
    if (properties !== null) {
      properties.forEach((property) => {
        registerQueryClient(
          property.id,
          createQueryClient({ "X-Property-Id": property.id }),
        );
      });

      const currentPropertyId = getStoredPropertyId();
      if (currentPropertyId) {
        setKohostPropertyId(currentPropertyId);
      }
      const isValidPropertyId = properties.some(
        (property) => property.id === currentPropertyId,
      );
      if (!isValidPropertyId || !currentPropertyId) {
        storePropertyId(properties[0].id);
        setKohostPropertyId(properties[0].id);
      }
    }

    return {
      organization,
      properties,
      auth,
    };
  };

export const Root = ({ children }) => {
  return (
    <AppSuspense>
      <RootData>{children}</RootData>
    </AppSuspense>
  );
};

const RootData = ({ children }) => {
  // Get the loader data
  const { organization } = useLoaderData();
  const { initializeTheme, initialized } = useTheme();

  if (!initialized && initializeTheme) {
    initializeTheme(organization?.appManifest);
  }

  // We render the children regardless of whether we have data or not
  // This allows the app to render even if the data is still loading
  return <>{children}</>;
};
