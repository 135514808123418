import { MediaFile } from "@kohost/api-client/models";
import { createContext, useContext, useState } from "react";
import { createAppBranding, createAppManifest } from "../browser/theme";

const themeContext = createContext({
  appManifest: null,
  icon: null,
  updateTheme: () => {},
  initializeTheme: () => {},
  initialized: false,
});

export const useThemeContext = () => {
  const [initialized, setInitialized] = useState(false);
  const [appManifest, setAppManifest] = useState(null);

  const appIcon = appManifest && appManifest.icons && appManifest.icons[0];

  const iconMedia = appIcon
    ? new MediaFile({
        url: appIcon.src,
        mimeType: "image/*",
      })
    : null;

  const updateTheme = (manifest) => {
    if (manifest) {
      createAppManifest(manifest);
      createAppBranding(manifest);
      setAppManifest(manifest);
    }
  };

  const initializeTheme = (manifest) => {
    if (manifest) {
      setInitialized(true);
      updateTheme(manifest);
    }
  };

  return {
    appManifest,
    icon: iconMedia,
    updateTheme,
    initializeTheme,
    initialized,
  };
};

export const ThemeProvider = ({ children }) => {
  const { appManifest, icon, updateTheme, initializeTheme, initialized } =
    useThemeContext();

  return (
    <themeContext.Provider
      value={{ appManifest, icon, updateTheme, initializeTheme, initialized }}
    >
      {children}
    </themeContext.Provider>
  );
};

export const useTheme = () => {
  const context = useContext(themeContext);

  if (!context) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }

  return context;
};
