import { cn } from "@/lib/utils";

import { Skeleton } from "@/components/ui/skeleton";
import React from "react";

import { MediaFile } from "@kohost/api-client/models/mediaFile";

const DynamicResponsiveImage = ({
  src,
  mediaFile,
  alt,
  className,
  params = {},
  showLoader = false,
  maxWidth = 3840,
}) => {
  const [imageLoaded, setImageLoaded] = React.useState(false);
  const deviceDpr = window.devicePixelRatio || 1;

  const tailwindBreakpoints = [
    320, 640, 768, 1024, 1280, 1536, 1920, 2560, 3840,
  ];

  const breakPoints = tailwindBreakpoints.reduce((acc, width) => {
    if (width <= maxWidth) {
      acc.push(width);
    }
    return acc;
  }, []);

  let media = null;

  try {
    media = mediaFile
      ? new MediaFile(mediaFile)
      : new MediaFile({ url: src, mimeType: "image/*" });
  } catch (error) {
    console.log(error);
    console.log(error.cause);
  }

  if (media && !media.url && typeof src === "string") {
    media.url = src;
  }

  const imgCx = cn(className, {
    "d-none": showLoader && !imageLoaded,
  });

  const loaderParams = {};

  if (params.width && params.height) {
    loaderParams.style = {
      aspectRatio: `${params.width}/${params.height}`,
    };
  }

  if (!media) return null;
  if (!media.url && !src) return null;

  const isDyanmic = media.mimeType === "image/*";

  return (
    <picture>
      {breakPoints.map((width, index) => {
        let mediaQuery =
          index === 0
            ? `(max-width: ${breakPoints[index]}px)`
            : `(min-width: ${breakPoints[index - 1]}px) and (max-width: ${
                width - 1
              }px)`;

        if (index === breakPoints.length - 1) {
          mediaQuery = `(min-width: ${breakPoints[index - 1]}px)`;
        }

        if (!isDyanmic) return null;

        return (
          <source
            key={index}
            media={mediaQuery}
            srcSet={media.createImageVariant({
              width: index === 0 ? width : breakPoints[index - 1],
              dpr: deviceDpr,
              ...params,
            })}
          />
        );
      })}
      {showLoader && !imageLoaded && <Skeleton {...loaderParams} />}
      <img
        src={
          isDyanmic
            ? media.createImageVariant({
                width: maxWidth,
                dpr: deviceDpr,
                ...params,
              })
            : src
        }
        alt={alt}
        className={imgCx}
        onLoad={() => setImageLoaded(true)}
      />
    </picture>
  );
};

export default DynamicResponsiveImage;
