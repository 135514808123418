import { getFirstItem } from "@/lib/helpers";
import { fetchOptions } from "@/lib/hooks/use-fetch";

import {
  CheckVerificationCodeCommand,
  DescribeMyAuthCommand,
  DescribeSelfCommand,
  LoginFinishCommand,
  LoginStartCommand,
  LogoutSelfCommand,
  RequestLoginTokenCommand,
  SendVerificationCodeCommand,
  UpdateSelfCommand,
} from "@kohost/api-client/useCases";
import { createMutationContext, fetchWithContext } from "./queryClient";
export const AUTH_QUERY_KEY = "auth";
export const AUTH_QUERY_KEY_SELF = [AUTH_QUERY_KEY, "self"];

export const authQuery = () =>
  fetchOptions({
    queryFn: fetchWithContext,
    queryKey: [AUTH_QUERY_KEY],
    meta: {
      command: DescribeMyAuthCommand,
      commandArgs: { data: {} },
      transformResponse: getFirstItem,
    },
  });

export const selfQuery = (query = {}, transformResponse = getFirstItem) =>
  fetchOptions({
    queryFn: fetchWithContext,
    queryKey: AUTH_QUERY_KEY_SELF,
    meta: {
      command: DescribeSelfCommand,
      commandArgs: { query },
      transformResponse,
    },
  });

export const tokenQuery = () =>
  fetchOptions({
    queryFn: fetchWithContext,
    queryKey: [AUTH_QUERY_KEY, "token"],
    meta: {
      command: RequestLoginTokenCommand,
      commandArgs: { data: {} },
      transformResponse: getFirstItem,
    },
    staleTime: 1000 * 60 * 15, // 15 minutes
  });

export const updateSelfMutation = (queryClient) => ({
  mutationFn: (vars) => {
    const context = createMutationContext(queryClient, {
      command: UpdateSelfCommand,
      commandArgs: { data: vars },
      transformResponse: getFirstItem,
    });

    return fetchWithContext(context);
  },
});

export const sendVerificationCodeMutation = (queryClient) => ({
  mutationFn: (vars) => {
    const context = createMutationContext(queryClient, {
      command: SendVerificationCodeCommand,
      commandArgs: { data: vars },
      transformResponse: getFirstItem,
    });

    return fetchWithContext(context);
  },
});

export const checkVerificationCodeMutation = (queryClient) => ({
  mutationFn: (vars) => {
    const context = createMutationContext(queryClient, {
      command: CheckVerificationCodeCommand,
      commandArgs: { data: vars },
      transformResponse: getFirstItem,
    });

    return fetchWithContext(context);
  },
});

export const loginStartMutation = (queryClient) => ({
  mutationFn: (vars) => {
    const context = createMutationContext(queryClient, {
      command: LoginStartCommand,
      commandArgs: vars,
      transformResponse: getFirstItem,
    });

    return fetchWithContext(context);
  },
});

export const loginFinishMutation = (queryClient) => ({
  mutationFn: (vars) => {
    const context = createMutationContext(queryClient, {
      command: LoginFinishCommand,
      commandArgs: vars,
      transformResponse: getFirstItem,
    });

    return fetchWithContext(context);
  },
});

export const logoutMutation = (queryClient) => ({
  mutationFn: () => {
    const context = createMutationContext(queryClient, {
      command: LogoutSelfCommand,
      commandArgs: { data: {} },
      transformResponse: getFirstItem,
    });

    return fetchWithContext(context);
  },
});

export const requestLoginTokenMutation = (queryClient) => ({
  mutationFn: (vars) => {
    const context = createMutationContext(queryClient, {
      command: RequestLoginTokenCommand,
      commandArgs: vars,
      transformResponse: getFirstItem,
    });

    return fetchWithContext(context);
  },
});
