import {
  getUserOrganizationPermissions as getOrgPermissions,
  getUserPropertyPermissions as getPropertyPermissions,
  getRolesFromPermissions,
  sortRoles,
} from "lib/auth/roles";
import { isIOS } from "../browser/device";
import { getFirstItem } from "../helpers";
import {
  AUTH_QUERY_KEY_SELF,
  authQuery,
  checkVerificationCodeMutation,
  loginFinishMutation,
  loginStartMutation,
  logoutMutation,
  requestLoginTokenMutation,
  selfQuery,
  sendVerificationCodeMutation,
  tokenQuery,
  updateSelfMutation,
} from "../queries/auth";
import { getQueryClient, useOptimisticUpdate } from "../queries/queryClient";
import { useFetch, useMutate } from "./use-fetch";
import { useLocalStorage } from "./use-local-storage";
import { useOrganization } from "./use-organization";
import { usePasskey } from "./use-passkey";
import { useCurrentProperty } from "./use-properties";
import { useTheme } from "./use-theme";

const scopePermissions = (response, orgId) => {
  const self = getFirstItem(response);
  const permissionsHere = getOrgPermissions(self, orgId);
  self.permissions = permissionsHere;
  return self;
};

export const useAuth = () => {
  const appQueryClient = getQueryClient("app");

  const { organization } = useOrganization();
  const { updateTheme, appManifest } = useTheme();
  const organizationId = organization?.id;

  const { data: auth } = useFetch(authQuery(), appQueryClient);

  const { data: self } = useFetch(
    selfQuery({}, (response) => scopePermissions(response, organizationId)),
    appQueryClient,
  );

  const { data: token } = useFetch(
    {
      ...tokenQuery(),
      enabled: Boolean(isIOS() && auth),
      staleTime: 1000 * 60 * 15, // 15 minutes
    },
    appQueryClient,
  );

  // This is a hack to get iOS users to automatically login when they save
  // the app to home screen. Since Safari doesn't share cache/cookies, we modify
  // the manifest to use the token as a query param, and then automatically log
  // the user in when the app is opened.
  if (token && appManifest && !appManifest.start_url.includes("pwaToken")) {
    const startUrl = window.location.origin + "/?pwaToken=" + token.token;
    const newManifest = structuredClone(organization.appManifest);
    newManifest.start_url = startUrl;
    updateTheme(newManifest);
  }

  const updateSelf = useMutate(
    {
      ...updateSelfMutation(appQueryClient),
      ...useOptimisticUpdate(AUTH_QUERY_KEY_SELF, appQueryClient),
      onSuccess: (user) => {
        const permissionsHere = getOrgPermissions(user, organizationId);
        user.permissions = permissionsHere;
        appQueryClient.setQueryData(AUTH_QUERY_KEY_SELF, user);
      },
      onMutate: async (data) => {
        const isFetching = appQueryClient.isFetching({
          queryKey: AUTH_QUERY_KEY_SELF,
        });

        if (isFetching) {
          await appQueryClient.cancelQueries({
            queryKey: AUTH_QUERY_KEY_SELF,
            exact: true,
          });
        }

        const previousData = appQueryClient.getQueryData(AUTH_QUERY_KEY_SELF);
        appQueryClient.setQueryData(AUTH_QUERY_KEY_SELF, {
          ...previousData,
          ...data,
        });
        return { previous: previousData };
      },
    },
    appQueryClient,
  );

  const sendVerificationCode = useMutate(
    sendVerificationCodeMutation(appQueryClient),
    appQueryClient,
  );
  const checkVerificationCode = useMutate(
    checkVerificationCodeMutation(appQueryClient),
    appQueryClient,
  );
  const startLogin = useMutate(
    loginStartMutation(appQueryClient),
    appQueryClient,
  );
  const finishLogin = useMutate(
    loginFinishMutation(appQueryClient),
    appQueryClient,
  );
  const getToken = useMutate(
    requestLoginTokenMutation(appQueryClient),
    appQueryClient,
  );
  const logout = useMutate(
    {
      ...logoutMutation(appQueryClient),
      onSuccess: () => {
        appQueryClient.removeQueries({
          queryKey: AUTH_QUERY_KEY_SELF,
          exact: true,
        });
      },
    },
    appQueryClient,
  );

  const { register: registerPasskey, getSavedRegistations } = usePasskey();

  return {
    // User
    /**
     * @deprecated use currentUser instead
     */
    data: self,
    currentUser: self,
    // Mutations
    updateSelf,
    // Passkeys
    registerPasskey,
    getSavedPasskeys: getSavedRegistations,
    // Login
    sendVerificationCode,
    checkVerificationCode,
    startLogin,
    finishLogin,
    getToken,
    logout,
  };
};

export const usePropertyAuth = () => {
  const { organization } = useOrganization();
  const { currentUser } = useAuth();
  const { id: propertyId } = useCurrentProperty();

  const { roles, currentUserRole, setCurrentUserRole } = useRoles(
    currentUser,
    organization.id,
    propertyId,
  );

  return {
    currentUser,
    roles,
    currentRole: currentUserRole,
    changeRole: setCurrentUserRole,
  };
};

const useRoles = (self, orgId, propertyId) => {
  const ROLE_KEY = `kohost.property.${propertyId}.currentRole`;

  const currentUserPropertyPermissions = getPropertyPermissions(
    self,
    orgId,
    propertyId,
  );

  const userRoles = getRolesFromPermissions(
    currentUserPropertyPermissions,
  ).sort(sortRoles);

  const [currentUserRole, setCurrentUserRole] = useLocalStorage(
    ROLE_KEY,
    userRoles[0],
  );

  if (userRoles.length > 0 && propertyId) {
    if (!currentUserRole) setCurrentUserRole(userRoles[0]);
    else {
      const roleExists = userRoles.includes(currentUserRole);
      if (!roleExists) setCurrentUserRole(userRoles[0]);
    }
  }

  return {
    roles: userRoles,
    currentUserRole,
    setCurrentUserRole,
  };
};
