export const storeItem = (key, value) => {
  if (typeof value === "object") {
    value = JSON.stringify(value);
  }
  localStorage.setItem(key, value);
};

export const getItem = (key) => {
  const value = localStorage.getItem(key);
  if (!value) {
    return null;
  }
  const shouldParse = value.startsWith("[") || value.startsWith("{");
  try {
    return shouldParse ? JSON.parse(value) : value;
    // eslint-disable-next-line no-unused-vars
  } catch (error) {
    return value;
  }
};
