export function getFirstItem(array) {
  if (!Array.isArray(array)) return array;
  return array[0];
}

export function isEqual(value, other) {
  if (typeof value !== "object" && typeof other !== "object") {
    return Object.is(value, other);
  }

  if (value === null && other === null) {
    return true;
  }

  if (typeof value !== typeof other) {
    return false;
  }

  if (value === other) {
    return true;
  }

  if (Array.isArray(value) && Array.isArray(other)) {
    if (value.length !== other.length) {
      return false;
    }

    for (let i = 0; i < value.length; i++) {
      if (!isEqual(value[i], other[i])) {
        return false;
      }
    }

    return true;
  }

  if (Array.isArray(value) || Array.isArray(other)) {
    return false;
  }

  if (Object.keys(value).length !== Object.keys(other).length) {
    return false;
  }

  for (const [k, v] of Object.entries(value)) {
    if (!(k in other)) {
      return false;
    }

    if (!isEqual(v, other[k])) {
      return false;
    }
  }

  return true;
}
export default function mergeObjects(source, target) {
  for (const key in source) {
    if (typeof source[key] === "object") {
      if (!target.hasOwnProperty(key)) {
        target[key] = {};
      }
      mergeObjects(source[key], target[key]);
    } else {
      target[key] = source[key];
    }
  }
  return target;
}
export const sortByTimestamp = (a, b) => {
  const dateA = new Date(a?.timestamp);
  const dateB = new Date(b?.timestamp);
  return dateA - dateB;
};
export const sortByName = (a, b) => {
  const nameA = a.name.toUpperCase();
  const nameB = b.name.toUpperCase();
  if (nameA < nameB) return -1;
  if (nameA > nameB) return 1;
  return 0;
};

/**
 *
 * @param {object} obj
 * @param {RegExp} pattern
 * @returns {string[]}
 */
export function getMatchingKeys(obj, pattern) {
  return Object.keys(obj).filter((key) => pattern.test(key));
}

/**
 *
 * @param {URLSearchParams} params
 * @param {string | string[]} keys
 * @returns {URLSearchParams}
 */
export function removeAllParamsExcept(params, keys) {
  if (!keys) throw new Error("keys is required");
  const toKeep = Array.isArray(keys) ? keys : [keys];

  for (const key of params.keys()) {
    if (!toKeep.includes(key)) {
      params.delete(key);
    }
  }

  return params;
}

export function toTruthyObject(obj) {
  const copy = { ...obj };
  return Object.fromEntries(
    Object.entries(copy).filter(([, v]) => {
      if (v == null) return false;
      if (typeof v === "string") {
        return v.length > 0;
      }
      return Boolean(v);
    }),
  );
}
