import qs from "qs";
export const parseJwt = (tokenString) => {
  if (!tokenString) return null;
  try {
    const base64Url = tokenString.split(".")[1];
    const base64 = base64Url.replace("-", "+").replace("_", "/");
    return JSON.parse(window.atob(base64));
  } catch (error) {
    // invalid jwt
    console.error(error);
    return null;
  }
};

export const parseQueryString = (queryString) => {
  return qs.parse(queryString, {
    decoder(str, defaultDecoder, charset, type) {
      if (
        type === "value" &&
        /^(?:-(?:[1-9](?:\d{0,2}(?:,\d{3})+|\d*))|(?:0|(?:[1-9](?:\d{0,2}(?:,\d{3})+|\d*))))(?:.\d+|)$/.test(
          str,
        )
      ) {
        return parseFloat(str);
      }

      const keywords = {
        true: true,
        false: false,
        null: null,
        undefined: undefined,
      };
      if (type === "value" && str in keywords) {
        return keywords[str];
      }

      return defaultDecoder(str, defaultDecoder, charset);
    },
  });
};
