import React from "react";
import LoadingSpinner from "@/components/common/LoadingSpinner";

export const AppSuspense = ({ children }) => {
  return (
    <React.Suspense
      fallback={<LoadingSpinner width={36} height={36} fullScreen />}
    >
      {children}
    </React.Suspense>
  );
};
