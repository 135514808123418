import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import React from "react";

import { KohostHTTPClient as Client } from "@kohost/api-client/client";
export {
  queryOptions as fetchOptions,
  useQuery as useFetch,
  useQueries as useFetchAll,
  useMutation as useMutate,
  useQueryClient,
  useSuspenseQuery as useSuspenseFetch,
  useSuspenseQueries as useSuspenseFetchAll,
} from "@tanstack/react-query";

const { VITE_TANSTACK_DEVTOOLS } = import.meta.env;

import kohost from "@/lib/api/client";
import { constructEntity } from "@/lib/api/transforms";
import { isEqual } from "@/lib/helpers";

export function createFetchOptions(propertyId, queryKey) {
  if (!queryKey) throw new Error("queryKey is required");

  const headers = {};

  if (propertyId) {
    const propertyHeader = Client.defs.propertyHeader;
    headers[propertyHeader] = propertyId;
  }

  const prefix = ["properties"];

  if (propertyId) prefix.push(propertyId);

  let key = prefix;

  if (typeof queryKey === "string") key = [...key, queryKey];
  if (Array.isArray(queryKey)) key = [...key, ...queryKey];

  return {
    headers,
    key,
  };
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 15, // 15 minutes
      gcTime: 1000 * 60 * 60, // 1 hour
      refetchOnWindowFocus: false,
    },
  },
  queryCache: new QueryCache({
    onSuccess: (data, query) => {
      if (query.meta.cacheById) {
        const excludeQuery = query.meta.excludeQuery;
        const queryKey = excludeQuery
          ? query.queryKey.filter((k) => !isEqual(k, excludeQuery))
          : query.queryKey;

        if (Array.isArray(data)) {
          data.forEach((responseData) => {
            if (!responseData) return responseData;
            if (responseData.type) {
              try {
                queryClient.setQueryData(
                  [...queryKey, responseData.id],
                  (old) => {
                    if (!old) return constructEntity(responseData);
                    return constructEntity({
                      ...old,
                      ...responseData,
                    });
                  },
                );
              } catch (error) {
                console.error(error);
                if (error.cause) {
                  console.error(error.cause);
                }
                return responseData;
              }
            }
          });
        }
      }
    },
  }),
});

/**
 * @typedef {keyof typeof import('@kohost/api-client/useCases')} KohostUseCaseName
 * @typedef {import('@kohost/api-client/useCases')[KohostUseCaseName]} KohostUseCase
 *
 * @typedef {Object} FetchHandlerOptions
 * @property {KohostUseCase} useCase - The use case to call
 * @property {Object} [headers={}] - Headers to include in the request
 * @property {Object} [query={}] - Query parameters to include in the request
 * @property {Object} [data={}] - Data to include in the request
 * @property {Object} [options={}] - Additional options
 * @property {boolean} [options.firstOnly=false] - If true, return only the first item in the response
 */

/**
 *
 * @typedef {keyof typeof kohost} KohostUseCase
 * @param {FetchHandlerOptions} options
 */

export function fetchHandler({ useCase, options = {} }) {
  if (typeof useCase !== "object") throw new Error("useCase must be an object");

  return async function fetch() {
    try {
      const response = await kohost.send(useCase);
      const responseData = response.data;
      if (
        options.transformResponse &&
        typeof options.transformResponse === "function"
      ) {
        return options.transformResponse(responseData);
      }
      return responseData;
    } catch (error) {
      console.error(error);
      if (error.cause) console.error(error.cause);
      if (error.response?.data?.error?.type) {
        const errName = error.response.data.error.type;
        const errMessage = error.response.data.error.message;
        const errorCause = error.response.data.error.cause || null;
        throw new Error(`${errName}: ${errMessage}`, { cause: errorCause });
      } else throw error;
    }
  };
}

export function ProvideFetch({ children }) {
  return (
    <QueryClientProvider client={queryClient}>
      {children}
      {VITE_TANSTACK_DEVTOOLS && <ReactQueryDevtools initialIsOpen={false} />}
    </QueryClientProvider>
  );
}
