import { Toaster } from "@/components/ui/toaster";
import { ProvideScreenBlur } from "@/lib/hooks/use-screenBlur";
import { ProvideAppQueryClient } from "@/lib/queries/queryClient";
import Router from "@/Router";

import * as Sentry from "@sentry/react";

import React from "react";
import { AppUpdater } from "./AppUpdater";

function App() {
  console.log("APP COMPONENT INITIALIZING");
  return (
    <React.StrictMode>
      {/* <RouteErrorBoundary> */}
      <Sentry.ErrorBoundary fallback={<div>Error</div>}>
        <ProvideAppQueryClient>
          <ProvideScreenBlur>
            <Router />
          </ProvideScreenBlur>
        </ProvideAppQueryClient>
        <AppUpdater />
        <Toaster />
      </Sentry.ErrorBoundary>

      {/* </RouteErrorBoundary> */}
    </React.StrictMode>
  );
}

export default App;
