import { constructEntity } from "@/lib/api/transforms";
import { isEqual } from "@/lib/helpers";
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
  useQueryClient,
} from "@tanstack/react-query";
import { sendRequest } from "../api/client";

export const createQueryClient = (headers = {}) => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 1000 * 60 * 15, // 15 minutes
        gcTime: 1000 * 60 * 60, // 1 hour
        meta: {
          headers,
        },
      },
      mutations: {
        meta: {
          headers,
        },
      },
    },
    queryCache: new QueryCache({
      onSuccess: (data, query) => {
        if (query.meta?.cacheById) {
          const excludeQuery = query.meta.excludeQuery;
          const queryKey = excludeQuery
            ? query.queryKey.filter((k) => !isEqual(k, excludeQuery))
            : query.queryKey;

          if (Array.isArray(data)) {
            data.forEach((responseData) => {
              if (!responseData) return responseData;
              if (responseData.type) {
                try {
                  queryClient.setQueryData(
                    [...queryKey, responseData.id],
                    (old) => {
                      if (!old) return constructEntity(responseData);
                      return constructEntity({
                        ...old,
                        ...responseData,
                      });
                    },
                  );
                } catch (error) {
                  console.error("Error in query cache onSuccess:", error);
                  if (error.cause) {
                    console.error("Cause:", error.cause);
                  }
                  return responseData;
                }
              }
            });
          }
        }
      },
      onError: (error, query) => {
        if (
          error?.type === "AuthenticationError" &&
          error?.cause?.code === "LOGIN_REQUIRED"
        ) {
          const location = window.location;
          if (location.pathname !== "/login") {
            window.location.href = "/login";
          }
        }
        console.error("Query cache onError:", error, query);
      },
    }),
  });

  return queryClient;
};

const queryClients = {
  app: createQueryClient(),
};

export const registerQueryClient = (name, queryClient) => {
  queryClient.name = name;
  queryClients[name] = queryClient;

  return queryClient;
};

/**
 * @param {"app" & keyof typeof queryClients} name
 * @returns {QueryClient}
 */
export const getQueryClient = (name) => {
  return queryClients[name] || queryClients.app;
};

export function useOptimisticUpdate(queryKey, queryClient) {
  const defaultQueryClient = useQueryClient();

  const client = queryClient || defaultQueryClient;

  const updateQueryData = (data) => {
    if (!data) return;
    if (data.id) client.setQueryData([...queryKey, data.id], data);

    client.setQueriesData({ queryKey }, (cached) => {
      if (typeof cached === "undefined") return [data];

      if (Array.isArray(cached)) {
        if (cached.length === 0) return [data];
        const updatedCache = cached.map((cachedItem) => {
          // replace the item with the updated data
          if (cachedItem.id === data.id) return data;
          return cachedItem;
        });

        // add an item if it doesn't exist
        if (!updatedCache.some((item) => item.id === data.id)) {
          updatedCache.push(data);
        }

        return updatedCache;
      } else if (cached.id === data.id) return data;
    });
  };

  const optimisticUpdate = async (data) => {
    const isFetching = client.isFetching({ queryKey });

    if (isFetching) {
      await client.cancelQueries({ queryKey, exact: true });
    }

    const previousData = client.getQueryData(queryKey);
    client.setQueryData(queryKey, data);
    return { previous: previousData };
  };

  const onError = (error, variables, context) => {
    client.setQueryData(queryKey, context.previous);
  };

  return {
    onSuccess: updateQueryData,
    onError: onError,
    onMutate: optimisticUpdate,
  };
}

/**
 * @param {import('@tanstack/react-query').QueryFunctionContext} ctx
 */
export async function fetchWithContext(ctx) {
  const client = ctx.client;
  const meta = ctx.meta;

  const defaultOptions = client.getDefaultOptions();
  const headers = defaultOptions.queries?.meta?.headers || {};

  const command = meta.command;
  const args = meta.commandArgs || {};
  const transformResponse = meta.transformResponse;

  const useCaseArgs = {
    ...args,
    headers: { ...headers, ...args.headers },
  };

  const useCase = new command(useCaseArgs);

  const response = await sendRequest(useCase);
  if (transformResponse && typeof transformResponse === "function") {
    return transformResponse(response);
  }
  return response;
}

export const createMutationContext = (queryClient, meta) => {
  return {
    client: queryClient,
    meta,
  };
};

export const ProvideAppQueryClient = ({ children }) => {
  return (
    <QueryClientProvider client={queryClients.app}>
      {children}
    </QueryClientProvider>
  );
};
