import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { createContext, useContext, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { setKohostPropertyId } from "../api/client";
import { propertiesQuery, propertyQuery } from "../queries/properties";
import { getQueryClient } from "../queries/queryClient";
import { useFetch } from "./use-fetch";
import { useLocalStorage } from "./use-local-storage";

const { VITE_TANSTACK_DEVTOOLS } = import.meta.env;

export const useProperties = () => {
  const appQueryClient = getQueryClient("app");
  const { data: properties } = useFetch(propertiesQuery(), appQueryClient);

  return { properties };
};

export const useProperty = (id) => {
  const appQueryClient = getQueryClient("app");
  const { data: property } = useFetch(propertyQuery(id), appQueryClient);

  const isHospitality = Boolean(property?.discriminator === "hospitality");
  const isCommercial = Boolean(property?.discriminator === "commercial");
  const isEducation = Boolean(property?.discriminator === "education");

  return {
    id: property?.id,
    property,
    isHospitality,
    isCommercial,
    isEducation,
  };
};

export const useCurrentProperty = () => {
  const [, setSearchParams] = useSearchParams();
  const [propertyId, setPropertyId] = useCurrentPropertyId();

  const changeProperty = (propertyId) => {
    setSearchParams(new URLSearchParams());
    setPropertyId(propertyId);
    setKohostPropertyId(propertyId);
  };

  return { ...useProperty(propertyId), changeProperty: changeProperty };
};

// Create a context for the current property ID
const PropertyIdContext = createContext(null);

export const PROPERTY_ID_STORAGE_KEY = "kohost.currentPropertyId";

export const PropertyIdProvider = ({ children }) => {
  const [propertyId, setPropertyId] = useLocalStorage(
    PROPERTY_ID_STORAGE_KEY,
    null,
  );

  return (
    <PropertyIdContext.Provider value={[propertyId, setPropertyId]}>
      {children}
    </PropertyIdContext.Provider>
  );
};

export const ProvidePropertyQueryClient = ({ children }) => {
  const [propertyId] = useCurrentPropertyId();
  const memoizedQueryClient = useMemo(
    () => getQueryClient(propertyId),
    [propertyId],
  );

  return (
    <QueryClientProvider key={propertyId} client={memoizedQueryClient}>
      {VITE_TANSTACK_DEVTOOLS && <ReactQueryDevtools initialIsOpen={false} />}
      {children}
    </QueryClientProvider>
  );
};

export const useCurrentPropertyId = () => {
  const context = useContext(PropertyIdContext);

  if (context === undefined) {
    throw new Error(
      "useCurrentPropertyId must be used within a PropertyIdProvider",
    );
  }

  return context;
};
