import { getFirstItem } from "@/lib/helpers";
import { fetchOptions } from "@/lib/hooks/use-fetch";
import {
  DescribePropertyCommand,
  ListMyPropertiesCommand,
  UpdatePropertySettingsCommand,
} from "@kohost/api-client/useCases";
import { createMutationContext, fetchWithContext } from "./queryClient";
export const PROPERTIES_QUERY_KEY = "properties";

export const propertiesQuery = () =>
  fetchOptions({
    queryFn: fetchWithContext,
    queryKey: [PROPERTIES_QUERY_KEY],
    meta: {
      cacheById: true,
      command: ListMyPropertiesCommand,
      commandArgs: { data: {} },
    },
  });

export const propertyQuery = (id) =>
  fetchOptions({
    queryFn: fetchWithContext,
    queryKey: [PROPERTIES_QUERY_KEY, id],
    enabled: !!id,
    meta: {
      command: DescribePropertyCommand,
      commandArgs: { data: { id } },
      transformResponse: getFirstItem,
    },
  });

export const updatePropertySettingsMutation = (queryClient) => ({
  mutationFn: (vars) => {
    const context = createMutationContext(queryClient, {
      command: UpdatePropertySettingsCommand,
      commandArgs: { data: vars },
      transformResponse: getFirstItem,
    });

    return fetchWithContext(context);
  },
});
