import { fetchOptions } from "@/lib/hooks/use-fetch";
import { DescribeMyOrganizationCommand } from "@kohost/api-client/useCases";
import { getFirstItem } from "../helpers";
import { fetchWithContext } from "./queryClient";
export const ORGANIZATION_QUERY_KEY = "organization";

export const organizationQuery = () =>
  fetchOptions({
    queryFn: fetchWithContext,
    queryKey: [ORGANIZATION_QUERY_KEY],
    meta: {
      command: DescribeMyOrganizationCommand,
      commandArgs: { data: {} },
      transformResponse: getFirstItem,
    },
  });
