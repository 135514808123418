import React, { useEffect } from "react";
import {
  RouterProvider,
  ScrollRestoration,
  matchPath,
  useLocation,
  useOutlet,
} from "react-router-dom";

import { useScreenBlur } from "@/lib/hooks/use-screenBlur";
import { getQueryClient } from "@/lib/queries/queryClient";

import NotFound from "@/routes/404";
import { sentryBrowserRouter } from "@/services/sentry";
import { useDocumentTitle } from "@uidotdev/usehooks";
import { RouteErrorBoundary } from "./RouteErrorBoundary";

import { ProvidePropertyQueryClient } from "@/lib/hooks/use-properties";
import { useOrganization } from "./lib/hooks/use-organization";
import { PropertyIdProvider } from "./lib/hooks/use-properties";
import { ProvideScreenBlur } from "./lib/hooks/use-screenBlur";
import { ProvideSocketIo } from "./lib/hooks/use-socketIo";

import { getStoredPropertyId } from "@/lib/data/property";
import { LoginRoute, loader as loginLoader } from "@/routes/login";
import { Root, loader as rootLoader } from "@/routes/root";
import { ThemeProvider } from "./lib/hooks/use-theme";

const routes = [
  {
    index: true,
    id: "home",
    path: "/",
    name: "Home",
    async lazy() {
      const { Home, loader } = await import("./routes/home");
      const client = getQueryClient(getStoredPropertyId());
      return {
        Component: Home,
        loader: loader(client),
      };
    },
  },
  {
    path: "/verify-phone",
    name: "Verify Phone",
    async lazy() {
      const { VerifyPhone } = await import("./components/features/VerifyPhone");
      return {
        Component: VerifyPhone,
      };
    },
  },
  {
    path: "/sos",
    name: "SOS",
    async lazy() {
      const { SOSRoute, loader } = await import("./routes/sos");
      return {
        Component: SOSRoute,
        loader: loader(getQueryClient(getStoredPropertyId())),
      };
    },
  },
  {
    path: "/room-control",
    name: "Smart Building",
    async lazy() {
      const { loader } = await import("./routes/smart-building");
      return {
        loader: loader(getQueryClient(getStoredPropertyId())),
      };
    },
    children: [
      {
        index: true,
        name: "Smart Building Controls",
        async lazy() {
          const { SmartBuilding } = await import("./routes/smart-building");
          return {
            Component: SmartBuilding,
          };
        },
      },
      {
        path: "climate",
        name: "Climate Control",
        async lazy() {
          const { SmartBuildingClimate } = await import(
            "./routes/smart-building.climate"
          );
          return {
            Component: SmartBuildingClimate,
          };
        },
      },
      {
        path: "lights",
        name: "Light Control",
        async lazy() {
          const { SmartBuildingLights } = await import(
            "./routes/smart-building.lights"
          );
          return {
            Component: SmartBuildingLights,
          };
        },
      },
      {
        path: "shades",
        name: "Shade Control",
        async lazy() {
          const { SmartBuildingShades } = await import(
            "./routes/smart-building.shades"
          );
          return {
            Component: SmartBuildingShades,
          };
        },
      },
      {
        path: "irrigation",
        name: "Irrigation Control",
        async lazy() {
          const { SmartBuildingIrrigation } = await import(
            "./routes/smart-building.irrigation"
          );
          return {
            Component: SmartBuildingIrrigation,
          };
        },
      },
      {
        path: "tv",
        name: "Media Control",
        async lazy() {
          const { SmartBuildingMedia } = await import(
            "./routes/smart-building.media"
          );
          return {
            Component: SmartBuildingMedia,
          };
        },
      },
      {
        path: "cameras",
        name: "Camera Control",
        async lazy() {
          const { SmartBuildingCameras } = await import(
            "./routes/smart-building.cameras"
          );
          return {
            Component: SmartBuildingCameras,
          };
        },
      },
      {
        path: "security",
        name: "Security Control",
        async lazy() {
          const { SmartBuildingSecurity } = await import(
            "./routes/smart-building.security"
          );
          return {
            Component: SmartBuildingSecurity,
          };
        },
      },
      {
        path: "pa",
        name: "PA System Control",
        async lazy() {
          const { SmartBuildingPA } = await import(
            "./routes/smart-building.pa"
          );
          return {
            Component: SmartBuildingPA,
          };
        },
      },
      {
        path: "doors",
        name: "Access  Control",
        async lazy() {
          const { SmartBuildingDoors } = await import(
            "./routes/smart-building.doors"
          );
          return {
            Component: SmartBuildingDoors,
          };
        },
      },
    ],
  },
  {
    path: "/check-in",
    name: "Check In",
    async lazy() {
      const { CheckIn, CheckInErrorBoundary } = await import(
        "./components/features/CheckIn"
      );
      return {
        Component: CheckIn,
        errorElement: <CheckInErrorBoundary />,
      };
    },
  },
  {
    path: "/check-out",
    name: "Check Out",
    async lazy() {
      const { CheckOut } = await import("./components/features/CheckOut");
      return {
        Component: CheckOut,
      };
    },
  },
  {
    path: "/users/*",
    name: "Users",
    async lazy() {
      const { Users } = await import("./components/features/Users");
      return {
        Component: Users,
      };
    },
  },
  {
    path: "/timesheets/*",
    name: "Time Tracking",
    async lazy() {
      const { TimeTracking } = await import(
        "./components/features/Concierge/TimeTracking"
      );
      return {
        Component: TimeTracking,
      };
    },
  },

  {
    path: "/concierge",
    name: "Concierge",

    children: [
      {
        index: true,
        name: "Concierge",
        async lazy() {
          const { ConciergeRoute, loader } = await import("./routes/concierge");
          return {
            loader: loader(getQueryClient(getStoredPropertyId())),
            Component: ConciergeRoute,
          };
        },
      },
      {
        name: "Concierge Settings",
        path: "settings",
        async lazy() {
          const { loader, ConciergeSettingsRoute } = await import(
            "./routes/concierge.settings"
          );
          return {
            loader: loader(getQueryClient(getStoredPropertyId())),
            Component: ConciergeSettingsRoute,
          };
        },
      },
    ],
  },
  {
    path: "/device-management",
    name: "Device Management",
    async lazy() {
      const { DeviceManagement } = await import(
        "./components/features/DeviceManagement"
      );
      return {
        Component: DeviceManagement,
      };
    },
  },
  {
    path: "/space-management",
    name: "Space Management",
    async lazy() {
      const { SpaceManagement } = await import(
        "./components/features/SpaceManagement"
      );
      return {
        Component: SpaceManagement,
      };
    },
  },
];

const Routes = () => {
  const location = useLocation();
  const currentOutlet = useOutlet();

  const { unblurScreen } = useScreenBlur();
  const { organization } = useOrganization();

  useEffect(() => {
    unblurScreen();
  }, [location.pathname]);

  let { name } =
    routes.find((route) => {
      // check if pathname is exactly route.path or a child of route.path
      const match = matchPath(route.path, location.pathname);
      if (match) return true;
      if (location.pathname.startsWith(route.path) && route.path !== "/")
        return true;
      return false;
    }) ?? {};

  useDocumentTitle(
    `${name} | ${organization?.name || "Kohost"} powered by Kohost`,
  );

  return (
    <ThemeProvider>
      <Root>
        <PropertyIdProvider>
          <ProvidePropertyQueryClient>
            <ProvideSocketIo>
              <ProvideScreenBlur>
                <div className="page">{currentOutlet}</div>
              </ProvideScreenBlur>
            </ProvideSocketIo>
          </ProvidePropertyQueryClient>
        </PropertyIdProvider>

        <ScrollRestoration />
      </Root>
    </ThemeProvider>
  );
};

const Router = () => {
  const router = sentryBrowserRouter([
    {
      id: "root",
      path: "/",
      element: <Routes />,
      errorElement: <RouteErrorBoundary />,
      loader: rootLoader(getQueryClient("app")),
      children: routes.map((route) => ({
        index: route.index,
        path: route.path === "/" ? undefined : route.path,
        element: route.element,
        children: route.children,
        lazy: route.lazy,
        state: route.state,
        id: route.id,
      })),
    },

    {
      id: "login",
      path: "/login",
      loader: loginLoader(getQueryClient("app")),
      element: (
        <Root>
          <LoginRoute />
        </Root>
      ),
    },

    {
      id: "404",
      path: "*",
      element: <NotFound />,
    },
  ]);
  return <RouterProvider router={router} />;
};

export default Router;
