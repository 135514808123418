import {
  browserSupportsWebAuthn,
  browserSupportsWebAuthnAutofill,
  startAuthentication,
  startRegistration,
} from "@simplewebauthn/browser";

import { useMutate } from "@/lib/hooks/use-fetch";
import kohost from "lib/api/client";

import {
  DescribeMyPasskeyRegistrationsCommand,
  FinishRegisterPasskeyCommand,
  StartRegisterPasskeyCommand,
} from "@kohost/api-client/useCases";

const startPasskeyRegistration = async () => {
  try {
    const cmd = new StartRegisterPasskeyCommand({});
    const response = await kohost.send(cmd);
    const registrationData = response.data[0];
    return registrationData;
  } catch (error) {
    console.error(error);
  }
};

const getSavedRegistations = async () => {
  try {
    const cmd = new DescribeMyPasskeyRegistrationsCommand({});
    const response = await kohost.send(cmd);
    const registrations = response.data;
    return registrations;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const usePasskey = () => {
  const authenticate = async (options) => {
    return await startAuthentication(options);
  };

  const registerPasskey = async () => {
    const { challengeId, options } = await startPasskeyRegistration();
    const challengeResponse = await startRegistration(options);

    const cmd = new FinishRegisterPasskeyCommand({
      data: { challengeId, challengeResponse },
    });

    const request = kohost.createRequest(cmd);
    return await kohost.send(request);
  };

  const registerMutation = useMutate({
    mutationFn: registerPasskey,
    refetchOnWindowFocus: false,
    retry: false,
  });

  return {
    register: registerMutation,
    authenticate,
    getSavedRegistations,
    browserSupportsWebAuthn,
    browserSupportsWebAuthnAutofill,
  };
};
