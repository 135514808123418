import { demoOrgs, getOrganizationId } from "@/lib/api/organization";

const { DEV, VITE_MSW } = import.meta.env;

export function register() {
  return new Promise((resolve) => {
    const orgId = getOrganizationId();
    const isDemo = demoOrgs.includes(orgId);

    const file = DEV ? "dev-sw.js" : "service-worker.js";

    const workerUrl = new URL(file, window.location.origin);
    // set search params
    workerUrl.searchParams.set("enableMocking", isDemo ? "true" : "false");

    if ("serviceWorker" in navigator) {
      window.addEventListener("load", () => {
        navigator.serviceWorker
          .register(workerUrl.href, { scope: "/" })
          .then((registration) => {
            console.log(
              "ServiceWorker registration successful with scope: ",
              registration.scope,
            );

            if (registration.waiting) {
              registration.waiting.postMessage({ type: "SKIP_WAITING" });
            }

            if (Boolean(VITE_MSW == "false" || !VITE_MSW) && !isDemo) {
              return resolve({ registration });
            }

            if (VITE_MSW || isDemo) {
              import("./mocks/browser").then(({ worker }) => {
                // if this is a demo, dynamically import the handlers from "./handlers-${orgId}.js"
                // otherwise, import the default handlers from "./handlers.js"

                import(`./mocks/handlers`)
                  .then(async ({ handlers, getMockData }) => {
                    await getMockData(orgId);
                    await worker
                      .start({
                        serviceWorker: {
                          url: workerUrl.href,
                        },
                        onUnhandledRequest: "bypass",
                      })
                      .catch(resolve);
                    worker.use(...handlers);
                    console.log("resolving");
                    return resolve({ worker });
                  })
                  .catch(resolve);
              });
            } else {
              console.log("resolving");
              resolve({ registration });
            }

            // resolve({ registration });
          })
          .catch((error) => {
            console.log("ServiceWorker registration failed: ", error);
            resolve();
          });
      });
    }
  });
}
