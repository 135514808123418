import * as Sentry from "@sentry/react";
import React from "react";
import { redirect, useRouteError } from "react-router-dom";
import { constructError } from "./lib/api/transforms";
import "./services/sentry";

const failedImportMessages = [
  "failed to fetch dynamic import",
  "loading chunk",
  "failed to fetch dynamically imported module",
];

export const RouteErrorBoundary = ({ fallback }) => {
  const error = useRouteError();
  if (error) console.error(error);
  const errMessage = error?.message?.toLowerCase() || "";
  const isFailedImport = failedImportMessages.some((msg) =>
    errMessage.includes(msg),
  );

  const isAuthorizationError = error?.type === "AuthorizationError";
  const isLoginRequiredError =
    error?.type === "AuthenticationError" &&
    error?.cause?.code === "LOGIN_REQUIRED";

  const isNotFoundError = error?.type === "NotFoundError";

  React.useEffect(() => {
    let exception = error;
    const isError = exception instanceof Error;
    if (!isError) {
      exception = constructError(error);
    }
    if (exception) Sentry.captureException(exception);
  }, [error]);

  if (isFailedImport)
    return (
      <ErrorMessageScreen
        title="Update Required"
        message="Your app needs an update in order to continue. Please tap the button below."
        buttonLink="/"
        buttonText="Update Now"
      />
    );
  if (isAuthorizationError)
    return (
      <ErrorMessageScreen
        title="Unauthorized"
        message="You do not have permission to access this page. If you believe this is an error, please contact your administrator."
        buttonLink="/"
        buttonText="Back Home"
      />
    );
  if (isNotFoundError)
    return (
      <ErrorMessageScreen
        title="Not Found"
        message="The page you are looking for does not exist."
        buttonLink="/"
        buttonText="Back Home"
      />
    );
  if (isLoginRequiredError && window.location.pathname !== "/login")
    return redirect("/login");
  if (fallback) return fallback;
  return (
    <div className="grid h-screen animate-fadeIn place-items-center">
      <div className="container mx-auto flex max-w-lg flex-col items-center rounded-md bg-river-bed-alt p-8">
        <h1 className="mb-2 text-2xl font-medium">
          Oops! Something went wrong here.
        </h1>
        <p className="mb-4 text-lg text-light-gray-300">
          Our team has been notified so that we can get this fixed. If the error
          persists, please try again later.
        </p>
        <button
          onClick={() => {
            window.location.href = "/";
          }}
          className="block rounded-md bg-bright-blue px-8 py-2 text-white"
        >
          Back Home
        </button>
      </div>
    </div>
  );
};

export const ErrorMessageScreen = ({
  title,
  message,
  buttonLink,
  buttonText,
}) => {
  return (
    <div className="grid h-screen animate-fadeIn place-items-center">
      <div className="container mx-auto flex max-w-lg flex-col items-center rounded-md bg-river-bed-alt p-8">
        <h1 className="text-2xl font-medium">{title}</h1>
        <h3 className="mb-4 text-center text-lg text-light-gray-300">
          {message}
        </h3>
        <button
          onClick={() => {
            window.location.href = buttonLink;
          }}
          className="block rounded-md bg-bright-blue px-8 py-2 text-white"
        >
          {buttonText}
        </button>
      </div>
    </div>
  );
};

export default RouteErrorBoundary;
