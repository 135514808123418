import { toast } from "@/components/ui/toast";
import { useCallback, useEffect, useRef } from "react";

export const AppUpdater = () => {
  const intervalRef = useRef(null);

  const handleUpdate = useCallback((registration) => {
    const newWorker = registration.waiting;

    if (newWorker) {
      toast("App Update Available", {
        id: "app-update",
        duration: Infinity,
        description:
          "A new version of the app is available. Please reload to update.",
        action: {
          label: "Update",
          onClick: () => {
            toast.dismiss("app-update");
            window.location.reload();
          },
        },
      });
    }
  }, []);

  useEffect(() => {
    let ignore = false;
    if ("serviceWorker" in navigator) {
      // Check for waiting workers on load
      navigator.serviceWorker.ready.then((registration) => {
        // Listen for new updates
        registration.addEventListener("updatefound", () => {
          const newWorker = registration.installing;

          if (newWorker) {
            newWorker.addEventListener("statechange", () => {
              if (
                newWorker.state === "installed" &&
                navigator.serviceWorker.controller
              ) {
                handleUpdate(registration);
              }
            });
          }
        });

        if (!ignore) {
          intervalRef.current = setInterval(() => {
            registration.update();
          }, 1000 * 60);
        }

        return () => {
          ignore = true;
          clearInterval(intervalRef.current);
        };
      });
    }
  }, [handleUpdate]);

  return null;
};
