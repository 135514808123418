import React from "react";

import { cn } from "@/lib/utils";
import RadioCheckIcon from "./icons/RadioCheck";
import RadioCheckActiveIcon from "./icons/RadioCheckActive";

const CheckButton = ({ id, active, label, onChange, className }) => {
  return (
    <button
      key={label}
      className={cn(
        "inline-flex w-full items-center justify-between py-4 text-lg font-medium text-white",
        className,
      )}
      onClick={(e) => {
        e.preventDefault();
        onChange(id);
      }}
    >
      <span>{label}</span>
      <span>
        {active ? (
          <RadioCheckActiveIcon className="ml-auto h-6 w-6" />
        ) : (
          <RadioCheckIcon className="ml-auto h-6 w-6" />
        )}
      </span>
    </button>
  );
};

export default CheckButton;
