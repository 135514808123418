import * as Sentry from "@sentry/react";
import { getOrganizationId } from "lib/api/organization";
import { useEffect } from "react";
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

const { VITE_SENTRY_DSN, PROD } = import.meta.env;

Sentry.init({
  dsn: VITE_SENTRY_DSN || "",
  environment: PROD ? "production" : "development",
  integrations: [
    Sentry.replayIntegration(),
    Sentry.extraErrorDataIntegration(),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],

  tracesSampleRate: PROD ? 0.1 : 1.0,
  replaysSessionSampleRate: PROD ? 0.1 : 0.03,
  initialScope: {
    tags: { propertyId: getOrganizationId() },
  },
});

export const sentryBrowserRouter =
  Sentry.wrapCreateBrowserRouterV6(createBrowserRouter);

export const captureException = (error) => {
  console.log("sentry error", error);
  if (error instanceof Error) {
    Sentry.captureException(error);
  } else if (error.type && error.message) {
    Sentry.captureException(new Error(`${error.type}: ${error.message}`));
  }
};
