import LoadingSpinner from "components/common/LoadingSpinner";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
// import "./services/sentry";
import * as sw from "./swRegister";

const root = ReactDOM.createRoot(document.getElementById("root"));

const loadingRoot = ReactDOM.createRoot(
  document.getElementById("loading-root"),
);

loadingRoot.render(<LoadingSpinner fullScreen width={36} height={36} />);

/* msw is conditionally loaded based on env vars */
sw.register()
  .catch(console.error)
  .finally(() => {
    // Set the global flag to indicate service worker registration is complete
    loadingRoot.unmount();
    root.render(<App />);
  });
