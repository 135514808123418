import { KohostHTTPClient as Client } from "@kohost/api-client/client";
import { getApiUrl } from "lib/api/endpoints";
import { getOrganizationId } from "lib/api/organization";
import { constructEntity, constructError } from "./transforms";

let organization = getOrganizationId();

console.log("organization", organization);

if (organization === "auth") organization = null;

const apiUrl = getApiUrl();

export const kohost = new Client({
  url: `${apiUrl}v3/`,
  organizationId: organization,
  onSuccess: (response) => {
    if (Array.isArray(response.data)) {
      response.data = response.data.map((responseData) => {
        try {
          if (!responseData) return responseData;
          return constructEntity(responseData);
        } catch (error) {
          console.error(error);

          return responseData;
        }
      });
    } else if (response.data?.type) {
      try {
        response.data = constructEntity(response.data);
      } catch (error) {
        console.error(error);
        if (error.cause) {
          console.error(error.cause);
        }
      }
    }

    return response;
  },
});

export const PROPERTY_HEADER = Client.defs.propertyHeader;

kohost.on("PhoneVerificationRequired", () => {
  if (window.location.pathname !== "/verify-phone") {
    window.location.href = "/verify-phone";
  }
});

/**
 *
 * @param {import('@tanstack/react-query').QueryFunctionContext} ctx
 */
export const getContextHeaders = (ctx) => {
  const client = ctx.client;
  const defaultOptions = client.getDefaultOptions();
  if (defaultOptions.queries?.meta?.headers) {
    return defaultOptions.queries.meta.headers;
  }
  return {};
};

export const setKohostPropertyId = (propertyId) => {
  kohost.propertyId = propertyId;
};

export const setKohostOrganizationId = (organizationId) => {
  if (kohost.organizationId !== organizationId) {
    kohost.organizationId = organizationId;
  }
};

/**
 *
 * @deprecated use sendRequest instead
 */
export function sendCommand(useCase, options = {}) {
  if (typeof useCase !== "object") throw new Error("useCase must be an object");

  return async function fetch() {
    try {
      const response = await kohost.send(useCase);
      const responseData = response.data;
      if (
        options.transformResponse &&
        typeof options.transformResponse === "function"
      ) {
        return options.transformResponse(responseData);
      }
      return responseData;
    } catch (error) {
      const exception = constructError(error);
      throw exception;
    }
  };
}

export async function sendRequest(useCase) {
  try {
    const response = await kohost.send(useCase);
    const responseData = response.data;
    return responseData;
  } catch (error) {
    const exception = constructError(error);
    throw exception;
  }
}

export default kohost;
